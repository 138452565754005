<admin-page-simple-content id="store-fulfillers"
  [pageError]="pageError"
  [pageStatus]="pageStatus"
  [title]="'Fulfiller: ' + fulfillerId"
  [icon]="'print'">
  @if (fulfiller) {
    <div class="content">
      <div>
        @if (pageStatus === 'saveError' && pageError) {
          <admin-error [error]="pageError"></admin-error>
        }
      </div>
      <h2>Information</h2>
      <div class="actions">
        @if (canManageFulfillers()) {
          <button mat-stroked-button
            (click)="editFulfiller(fulfiller)"
            >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
        }
      </div>
      <div class="fulfiller-info">
        <div class="admin-field">
          <div class="label">Fulfiller ID</div>
          <div class="value">
            <span>
              <code adminCopyToClipboard>{{ fulfiller.id }}</code>
            </span>
          </div>
        </div>
        <div class="admin-field">
          <div class="label">Client</div>
          <div class="value">
            <span>
              @if (fulfiller.client) {
                <code adminCopyToClipboard>{{ fulfiller.client }}</code>
              }
              @if (!fulfiller.client) {
                <span>Not specified. When using this fulfiller the order are going to be set in status <span
                [adminStatusColor]="$any('AvailableForDownload')">AvailableForDownload</span></span>
              }
            </span>
          </div>
        </div>
        <div class="admin-field">
          <div class="label">Fulfiller App Id</div>
          <div class="value">
            <span>
              @if (fulfiller.fulfillerAppId) {
                <code
                adminCopyToClipboard>{{ fulfiller.fulfillerAppId }}</code>
              }
              @if (!fulfiller.fulfillerAppId) {
                <span>Not specified</span>
              }
            </span>
            @if (canManageFulfillers() && !fulfiller.fulfillerAppId) {
              <button mat-icon-button
                [disabled]="pageStatus === 'saving'"
                [matMenuTriggerFor]="manageFulfillerApp">
                <mat-icon>more_vert</mat-icon>
              </button>
            }
            <mat-menu #manageFulfillerApp>
              <button mat-menu-item (click)="createFulfillerAppAndLinkToIt(fulfiller)">
                <mat-icon>add</mat-icon>
                Create a new fulfiller app
              </button>
              <button mat-menu-item (click)="linkFulfillerApp(fulfiller)">
                <mat-icon>link</mat-icon>
                Link to an existing fulfiller app
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="admin-field">
          <div class="label">Library Package</div>
          <div class="value">
            @if (fulfiller.libraryPackage) {
              <a
                [attr.href]="'https://git.mediacliphub.com/librairies/' + fulfiller.libraryPackage.substring(0, fulfiller.libraryPackage.indexOf('/'))"
              rel="external" target="_blank">{{ fulfiller.libraryPackage }}</a>
            }
            @if (!fulfiller.libraryPackage) {
              <span>none</span>
            }
          </div>
        </div>
        <div class="admin-field">
          <div class="label">Base Url</div>
          <div class="value">
            <span>
              @if (fulfiller.url) {
                <code adminCopyToClipboard>{{ fulfiller.url }}</code>
              }
              @if (!fulfiller.url) {
                <span>Not specified</span>
              }
            </span>
          </div>
        </div>
        <div class="admin-field">
          <div class="label">Mapping Script</div>
          <div class="value">
            <span>
              @if (fulfiller.mappingScript) {
                <code adminCopyToClipboard>{{ fulfiller.mappingScript.script }}</code>
              }
              @if (!fulfiller.mappingScript) {
                <span>Not script</span>
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  }
</admin-page-simple-content>
