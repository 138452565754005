import {
    CreateFulfillerAppInput,
    CreateFulfillerInput,
    Fulfiller, FulfillerApp,
    Maybe, MediaclipMutationCreateFulfillerAppArgs,
    MediaclipMutationCreateFulfillerArgs,
    MediaclipMutationUpdateFulfillerArgs,
    UpdateFulfillerInput
} from 'schema-dotnet';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from './graph-dotnet-service';

@Injectable()
export class FulfillersService {

    constructor(
        private readonly graphDotnetService: GraphDotnetService
    ) {
    }

    list(): Observable<Fulfiller[]> {
        return this.graphDotnetService.query(`
                query getFulfillers {
                    fulfillers {
                        id
                        libraryPackage
                        url
                        client
                    }
                }
            `
        ).pipe(map(e => e.fulfillers));
    }

    getAuthorizedUsers(fulfillerId: string): Observable<Maybe<Fulfiller> | undefined> {
        return this.graphDotnetService.query(`
                query getFulfiller($fulfillerId: String!) {
                    fulfiller(id: $fulfillerId) {
                        id
                        authorizedUsers {
                            user {
                                email
                                picture
                            }
                            roles
                        }
                    }
                }
            `,
            { fulfillerId }
        ).pipe(map(e => e.fulfiller));
    }


    getFulfillerSecrets(fulfillerId: string): Observable<Maybe<Fulfiller> | undefined> {
        return this.graphDotnetService.query(`
                query getFulfiller($fulfillerId: String!) {
                    fulfiller(id: $fulfillerId) {
                        id
                        fulfillerAppId
                        secrets {
                            id
                            label
                        }
                    }
                }
            `,
            { fulfillerId }
        ).pipe(map(e => e.fulfiller));
    }

    load(fulfillerId: string): Observable<Maybe<Fulfiller> | undefined> {
        return this.graphDotnetService.query(`
                query getFulfiller($fulfillerId: String!) {
                    fulfiller(id: $fulfillerId) {
                        id
                        client
                        fulfillerAppId
                        libraryPackage
                        url
                        mappingScript {
                            script
                        }
                        form {
                            documentationLink
                            fields {
                                key
                                label
                                type
                                required
                                description
                                sampleValue
                            }
                        }
                    }
                }
            `,
            { fulfillerId }
        ).pipe(map(e => e.fulfiller));
    }

    createFulfiller(input: CreateFulfillerInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateFulfillerArgs>(`
                mutation createFulfiller($input: CreateFulfillerInput!) {
                    createFulfiller(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.createFulfiller));
    }

    updateFulfiller(input: UpdateFulfillerInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateFulfillerArgs>(`
                mutation updateFulfiller($input: UpdateFulfillerInput!) {
                    updateFulfiller(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.updateFulfiller));
    }

    createFulfillerApp(input: CreateFulfillerAppInput): Observable<FulfillerApp> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateFulfillerAppArgs>(`
                mutation createFulfillerApp($input: CreateFulfillerAppInput!) {
                    createFulfillerApp(input: $input) {
                        id
                        displayName
                    }
                }
            `, { input }
        ).pipe(map(x => x.createFulfillerApp));
    }
}
