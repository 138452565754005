import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DisplayCodeDialogData {
    text: string;
    mimeType: string;
    editable?: boolean;
}

export type DisplayCodeDialogResult = {
    text: string;
}

@Component({
    selector: 'admin-display-code-dialog',
    templateUrl: './display-code-dialog.component.html',
    styleUrls: ['./display-code-dialog.component.scss']
})
export class DisplayCodeDialogComponent {
    text: string;
    editable: boolean;

    constructor(
        public dialogRef: MatDialogRef<DisplayCodeDialogComponent, DisplayCodeDialogResult>,
        @Inject(MAT_DIALOG_DATA) public data: DisplayCodeDialogData
    ) {
        this.text = data.text;
        this.editable = !!data.editable;
    }

    edit() {
        this.dialogRef.close({ text: this.text });
    }
}
