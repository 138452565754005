import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Fulfiller, FulfillerClient } from '../../../schema-dotnet';

export interface EditFulfillerDialogResult {
    client?: FulfillerClient;
    appKey?: string;
    url?: string;
    libraryPackage?: string;
    fulfillerAppId?: string;
    mappingScript?: string;
}

@Component({
    templateUrl: './edit-fulfiller-dialog.component.html',
    styleUrls: ['../../shared/full-screen-dialog.scss', './edit-fulfiller-dialog.component.scss']
})
export class EditFulfillerDialogComponent {

    fulfillerInfo: EditFulfillerDialogResult;

    clients = Object.values(FulfillerClient);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Fulfiller,
        public dialogRef: MatDialogRef<EditFulfillerDialogResult>
    ) {
        this.fulfillerInfo = {
            appKey: '',
            url: data.url,
            client: data.client,
            fulfillerAppId: data.fulfillerAppId,
            libraryPackage: data.libraryPackage,
            mappingScript: data.mappingScript?.script ?? ''
        } as EditFulfillerDialogResult;
    }

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
